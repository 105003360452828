<template>
  <div>
    <section id="account">
      <div class="section-title section-title--sticky-light">
        Личный кабинет
      </div>
      <div class="how-register account-sidebar">
        <div class="how-register__sidebar">
          <div class="how-register-nav">
            <div
              v-for="(section, index) in accountSections"
              :key="index"
              :class="[
                  'how-register-nav__item',
                  { 'is-active': activeAccountSection === index },
                  { 'is-scrolled': section.scrolled }
                ]"
              :id="`register-nav-section-${index + 1}`"
              @click="scrollToSection(index)">
              <div class="how-register-nav__item__count">{{ index + 1 }}</div>
              <div class="how-register-nav__item__name">{{ section.name }}</div>
            </div>
            <div class="how-register-nav__gameban">
                <img src="@/assets/img/main/game-banner.png" @click="goToGamePage"
                     srcset="@/assets/img/main/game-banner@2x.png 2x" alt="">
            </div>
            <div class="how-register-nav__element">
              <img src="@/assets/img/main/sidebar-element.png"
                   srcset="@/assets/img/main/sidebar-element@2x.png 2x" alt="">
            </div>
          </div>
        </div>
        <div class="how-register__content">
          <div v-for="(section, index) in accountSections" :key="index" class="account-section" :ref="'section-' + index">
            <template v-if="section.component === 'PersonalData'">
              <div class="personal-data">
                <div class="personal-details">
                  <div class="personal-details-row">
                    <div class="personal-details__col">
                      <span>Имя</span>
                      <p> {{ accountUser.first_name }} </p>
                    </div>
                    <div class="personal-details__col">
                      <span>Фамилия</span>
                      <p> {{ accountUser.last_name }} </p>
                    </div>
                  </div>
                  <div class="personal-details-row">
                    <div class="personal-details__col">
                      <span>Email</span>
                      <p> {{ accountUser.email }} </p>
                    </div>
                    <div class="personal-details__col">
                      <span>Номер телефона</span>
                      <p> {{ accountUser.phone_number }} </p>
                    </div>
                  </div>
                  <div class="personal-details-row">
                    <div class="personal-details__btn">
                      <button class="button button-white" @click="openAccountDetailsPopup">Изменить данные</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="personal-data__gameban">
                <img src="@/assets/img/main/game-banner.png" @click="goToGamePage"
                     srcset="@/assets/img/main/game-banner@2x.png 2x" alt="">
              </div>
            </template>
            <template v-if="section.component === 'WinningPrizes'">
              <div class="winning-prizes">
                <div v-if="prizes.length > 0" class="winning-prizes-list">
                  <div v-for="(prize, index) in prizes" :key="index" class="prize-item">
                    <div class="prize-item__info">
                      <span :class="{'info-block': true, 'is-opened': isOpen[index]}">
                        <button class="info-block__open" @click="openBlock(index)"></button>
                        <span class="info-block__text">
                          <button class="info-block__close" @click="closeBlock(index)"></button>
                          {{ prize.greeting_text }}
                        </span>
                      </span>
                    </div>
                    <div class="prize-item__img">
                      <img :src="getImageSrc(prize.cabinet_image)" />
                    </div>
                    <div class="prize-item__title">
                      {{ prize.title }}
                    </div>
                  </div>
                </div>

                <div v-else class="empty">
                  <div class="empty__text">
                    Для участия в розыгрыше призов загрузите чек, а также не пропустите нашу викторину!
                  </div>
                  <div class="empty__btns">
                    <button class="button button-yellow button--upload" @click="triggerQrScan">Загрузить чек</button>
                    <button class="button button-yellow" @click="goToGamePage">Пройти викторину</button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="section.component === 'QuizSection'">
              <div class="users-check">
                <div class="users-check__btn" v-if="checks.results.length !== 0">
                  <button class="button button-yellow button--upload" @click="triggerQrScan">Загрузить чек</button>
                </div>

                <div class="empty" v-if="checks.results.length === 0">
                  <div class="empty__text">
                    Вы не загрузили ни одного чека.
                  </div>
                  <div class="empty__btns">
                    <button class="button button-yellow button--upload" @click="triggerQrScan">Загрузить чек</button>
                  </div>
                </div>

                <div class="users-check-list" v-else>
                  <div v-for="(check, cIndex) in checks.results" :key="check.id" :class="['check-item', getStatusClass(check.status)]">
                    <div class="check-item__status">
                      {{ getStatusText(check.status) }}
                      <span v-if="check.reject_reason" :class="{'info-block': true, 'is-opened': isOpen[prizes.length + cIndex]}">
                        <button class="info-block__open" @click="openBlock(prizes.length + cIndex)"></button>
                        <span class="info-block__text">
                          <button class="info-block__close" @click="closeBlock(prizes.length + cIndex)"></button>
                          {{ check.reject_reason }}
                        </span>
                      </span>
                    </div>

                    <div class="check-item__info"></div>

                    <div v-if="check.images" class="check-item__img">
                      <a v-for="(image, index) in check.images" :key="index" :href="getImageSrc(image)" :data-fancybox="'user-check-' + cIndex + '-' + index">
                        <img :src="getImageSrc(image)" />
                        <button class="button button-yellow">Открыть</button>
                      </a>
                    </div>

                    <div class="check-item__date">
                      <span>{{ formatDate(check.upload_date) }}</span>
                      <span>{{ formatTime(check.upload_date) }}</span>
                    </div>

                  </div>
                  <div v-if="checks.next" class="load-more-checks">
                    <button class="button button-white" @click="loadMoreChecks">Загрузить ещё</button>
                  </div>
                </div>

              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- Popup для изменения данных -->
    <div v-if="showAccountDetailsPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountDetailsPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountDetailsPopup" class="popup__close"></button>
        <div class="popup__title">Изменить данные</div>
        <div class="popup__content">
          <form @submit.prevent="changeAccountDetails" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="accountUser.first_name" @input="removeSpacesFirstName" name="first_name"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="accountUser.last_name" @input="removeSpacesLastName" name="last_name"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="accountUser.email" name="email"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Изменить данные</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showAccountDetailsSuccess" class="popup">
      <div class="popup__overlay" @click="closeAccountDetailsSuccess"></div>
      <div class="popup__window">
        <button @click="closeAccountDetailsSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваши данные успешно обновлены
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMainSettings, getToken, getUserInfo, changeProfile, setAuthHeader, getWinningPrizes, getUserChecks } from "@/api";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  name: 'PrivatePage',
  data() {
    return {
      rootUrl: window.location.origin,
      prizes: [],
      isOpen: [],
      errors: {},
      showAccountDetailsPopup: false,
      showAccountDetailsSuccess: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {},
      responseError: '',
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      accountDetailsForm: {
        first_name: '',
        last_name: '',
        email: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 1,
      statusTextMap: {
        0: 'Загружен',
        1: 'На проверке',
        3: 'Одобрен',
        4: 'Отклонен',
        5: 'Выиграл',
      },
      accountSections: [
        { name: 'Личные данные', component: 'PersonalData', scrolled: false },
        { name: 'Выигрынные призы', component: 'WinningPrizes', scrolled: false},
        { name: 'Загруженные чеки', component: 'QuizSection', scrolled: false },
      ],
    }
  },
  mounted() {
    Fancybox.bind("[data-fancybox]", {
    });
    this.initializeOpenState();
    this.fetchWinningPrizes();
    this.fetchUsersCheck();
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    loadMoreChecks() {
      if (this.checks.next) {
        this.checkOffset += 1;
        this.fetchUsersCheck();
      }
    },
    goToGamePage() {
      this.$router.push('/game');
      setTimeout(function(){
        window.scrollTo({
          top: '0',
          behavior: 'smooth'
        });
      }, 200)
    },
    initializeOpenState() {
      this.isOpen = [...this.prizes, ...this.checks.results].map(() => false);
    },
    openBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = true; // Установка состояния для открытия блока
      });
    },
    closeBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = false; // Установка состояния для закрытия блока
      });
    },
    getImageSrc(imageUrl) {
      // You can add any logic here to manipulate or format the image URL if needed
      return imageUrl;
    },
    getStatusText(status) {
      return this.statusTextMap[status] || 'Unknown';
    },
    getStatusClass(status) {
      return {
        'status-loaded': status === 0,
        'status-pending': status === 1,
        'status-approved': status === 3,
        'status-rejected': status === 4,
        'status-won': status === 5,
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
    async fetchWinningPrizes() {
      try {
        const prizes = await getWinningPrizes();
        this.prizes = prizes.results;
      } catch (error) {
        console.error('Error fetching winning prizes:', error);
      }
    },
    async fetchUsersCheck() {
      try {
        const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

        // Добавляем новые чеки к существующим
        this.checks.results.push(...checksData.results);

        // Обновляем значение next
        this.checks.next = checksData.next;

      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.last_name = input;
    },
    openAccountDetailsPopup() {
      this.showAccountDetailsPopup = true;
      this.responseError = '';
    },
    closeAccountDetailsPopup() {
      this.showAccountDetailsPopup = false;
    },
    openAccountDetailsPopupSuccess() {
      this.showAccountDetailsSuccess = true;
      this.responseError = '';
    },
    closeAccountDetailsSuccess() {
      this.showAccountDetailsSuccess = false;
    },
    async changeAccountDetails() {
      try {
        const { first_name, last_name, email } = this.accountUser;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        if (!email.includes('@') || !email.includes('.')) {
          this.errors.email = 'Введите корректный email';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await changeProfile(first_name, last_name, email);

        // Закрываем окно регистрации
        this.closeAccountDetailsPopup();
        this.openAccountDetailsPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    handleScroll() {
      const AccountSections = document.querySelectorAll('.account-section');

      let currentSectionIndex = -1;

      AccountSections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeAccountSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.accountSections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.accountSections.length; i++) {
          this.accountSections[i].scrolled = false;
        }
      } else {
        this.activeAccountSection = 0;
        this.accountSections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeAccountSectionIndex = index;
    },
    isActive(index) {
      return this.activeAccountSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeAccountSectionIndex;
    },
  },
  async created() {
    try {
      const [mainSettingsData] = await Promise.all([
        getMainSettings()
      ]);
      this.mainSettings = mainSettingsData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
};
</script>

<style>
/* Ваши стили */
</style>