<template>
  <div class="game-page"
       :class="[
       { 'game-page--started': quizStarted },
       quizCompleted && resultImage ? `game-page--result-${resultImage}` : ''
     ]">
    <template v-if="!quizStarted">
      <section id="quiz">
        <div class="section-title section-title--clear">
          Викторина
        </div>
        <div class="quiz">
          <div class="quiz__left">
            <QuizRules />
            <div class="quiz__action">
              <button @click="startQuiz" class="button button-yellow">Играть</button>
            </div>
          </div>
          <div class="quiz__right"></div>
        </div>
      </section>
    </template>
    <template v-else>
      <section id="quiz">
        <div class="section-title section-title--clear">
          Викторина
        </div>
        <QuizQuestions
          v-if="!quizCompleted"
          :currentQuestion="currentQuestion"
          :questionIndex="questionIndex"
          :isAnswerSubmitted="isAnswerSubmitted"
          :isCorrectAnswer="isCorrectAnswer"
          :totalQuestions="7"
          @answer-submitted="submitAnswer"
          @next-question="goToNextQuestion"
        />
        <template v-else>
          <QuizResults
            :correctAnswersCount="correctAnswersCount"
            :totalQuestions="7"
            :resultImage="resultImage"
            :congratulationMessage="congratulationMessage"
            :showShare="isAuthenticated"
            :quizToken="quizToken"
          />
        </template>
      </section>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import QuizRules from './QuizRules.vue';
import QuizQuestions from './QuizQuestions.vue';
import QuizResults from './QuizResults.vue';
import { getToken } from '@/api'; // Замените на вашу реализацию

const API_URL = 'https://api.hochland.testpromo.ru';
// const API_URL = 'https://api.xn--80aabfdla7bxcpv.xn--p1ai';

export default {
  name: 'GamePage',
  components: {
    QuizRules,
    QuizQuestions,
    QuizResults
  },
  data() {
    return {
      quizStarted: false,
      quizToken: '',
      currentQuestion: null,
      questionIndex: 0,
      quizCompleted: false,
      correctAnswersCount: 0,
      isAnswerSubmitted: false,
      isCorrectAnswer: false,
      nextQuestionData: null // Добавлено для хранения следующего вопроса
    };
  },
  computed: {
    isAuthenticated() {
      return !!getToken(); // Замените на вашу реализацию
    },
    resultImage() {
      if (this.correctAnswersCount >= 0 && this.correctAnswersCount <= 3) {
        return '1'; // Пример пути к изображению
      } else if (this.correctAnswersCount >= 4 && this.correctAnswersCount <= 5) {
        return '2'; // Пример пути к изображению
      } else if (this.correctAnswersCount >= 6 && this.correctAnswersCount <= 7) {
        return '3'; // Пример пути к изображению
      } else {
        return ''; // Обработка других случаев, если нужно
      }
    },
    congratulationMessage() {
      if (this.correctAnswersCount >= 0 && this.correctAnswersCount <= 3) {
        return "Любитель насладиться красотой набережных Санкт-Петербурга!";
      } else if (this.correctAnswersCount >= 4 && this.correctAnswersCount <= 5) {
        return "Кажется, Санкт-Петербург занимает отдельное место в твоем сердце!";
      } else if (this.correctAnswersCount >= 6 && this.correctAnswersCount <= 7) {
        return "Поздравляем! Ты - настоящий знаток топографии Санкт-Петербурга!";
      } else {
        return '';
      }
    },
  },
  methods: {
    async startQuiz() {
      try {
        const response = await axios.post(`${API_URL}/api/game/start-game`);
        this.quizToken = response.data.token;
        this.currentQuestion = response.data.next_question;
        this.quizStarted = true;
      } catch (error) {
        console.error('Error starting quiz:', error);
        // Handle error
      }
    },
    async submitAnswer(answerId) {
      try {
        const response = await axios.post(`${API_URL}/api/game/set-answer`, {
          token: this.quizToken,
          question_id: this.currentQuestion.id,
          answer_id: answerId
        });
        this.isCorrectAnswer = response.data.is_correct_answer;
        this.isAnswerSubmitted = true;
        if (this.isCorrectAnswer) {
          this.correctAnswersCount++;
        }
        this.nextQuestionData = response.data.next_question; // Сохранить следующий вопрос
      } catch (error) {
        console.error('Error submitting answer:', error);
        // Handle error
      }
    },
    goToNextQuestion() {
      if (this.questionIndex < 6) { // Assuming 7 questions in total (0-based index)
        this.currentQuestion = this.nextQuestionData;
        this.questionIndex++;
        this.isAnswerSubmitted = false;
        this.isCorrectAnswer = false;
      } else {
        this.quizCompleted = true;
      }
    }
  }
};
</script>

<style>
.footer {
  margin-top: 0;
}
</style>
