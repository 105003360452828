<template>
  <div class="quiz-questions">
    <div class="quiz quiz-questions">
      <div class="quiz__left">
        <div class="quiz-questions__title">
          {{ currentQuestion.text }}
        </div>
        <ul class="quiz-questions__btns">
          <li v-for="answer in currentQuestion.answers" :key="answer.id">
            <button
              class="button button-white"
              :disabled="isAnswerSubmitted"
              @click="submitAnswer(answer.id)"
            >
              {{ answer.text }}
            </button>
            <p v-if="isAnswerSubmitted && answer.id === selectedAnswerId" class="quiz-question-result-answer">
              <span v-if="isCorrectAnswer">Да, все верно!</span>
              <span v-else>Увы, ты не угадал(а)</span>
            </p>
          </li>
        </ul>
        <div class="quiz-questions__next">
          <button
            class="button button-yellow"
            :disabled="!isAnswerSubmitted"
            @click="nextQuestion">
            Далее
          </button>
        </div>
        <div class="progress-bar">
          <div
            class="progress-bar__step"
            v-for="step in totalQuestions"
            :key="step"
            :class="{
              'progress-bar__step--filled': step < questionIndex + 1,
              'progress-bar__step--active': step === questionIndex + 1
            }"
          ></div>
        </div>
      </div>
      <div class="quiz__right-clear">
        <img :src="currentQuestion.image" alt="Question Image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizQuestions',
  props: {
    currentQuestion: {
      type: Object,
      required: true
    },
    questionIndex: {
      type: Number,
      required: true
    },
    isAnswerSubmitted: {
      type: Boolean,
      required: true
    },
    isCorrectAnswer: {
      type: Boolean,
      required: true
    },
    totalQuestions: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedAnswerId: null
    };
  },
  methods: {
    submitAnswer(answerId) {
      this.selectedAnswerId = answerId;
      this.$emit('answer-submitted', answerId);
    },
    nextQuestion() {
      this.$emit('next-question');
    }
  }
};
</script>

<style scoped>
/* Здесь могут быть стили для компонента QuizQuestions.vue */
.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  background-color: rgba(255,255,255,.08);
  border-radius: 10rem;
}

.progress-bar__step {
  width: 100%;
  height: 10px;
}

.progress-bar__step:first-child {
  border-radius: 10rem 0 0 10rem;
}

.progress-bar__step--active:first-child {
  border-radius: 10rem !important;
}
.progress-bar__step--active {
  border-radius: 0 10rem 10rem 0 !important;
  background-color: #fff;
}

.progress-bar__step--filled {
  background-color: #fff;
}
</style>
