<template>
  <div>
    <div class="quiz-steps">
      <div class="quiz-steps__number">1</div>
      <div class="quiz-steps__info">
        <div class="quiz-steps__info__title">
          Переходите в викторину
        </div>
      </div>
    </div>
    <div class="quiz-steps">
      <div class="quiz-steps__number">2</div>
      <div class="quiz-steps__info">
        <div class="quiz-steps__info__title">
          Угадывайте
        </div>
        <div class="quiz-steps__info__text">
          С какого берега Невы был сделан тот или иной снимок Санкт-Петербурга и что находится на берегу напротив.
        </div>
      </div>
    </div>
    <div class="quiz-steps">
      <div class="quiz-steps__number">3</div>
      <div class="quiz-steps__info">
        <div class="quiz-steps__info__title">
          Участвуйте
        </div>
        <div class="quiz-steps__info__text">
          В розыгрыше специального приза «индивидуальная водная прогулка по г. Санкт-Петербургу на двоих»!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizRules'
};
</script>
