<template>
  <div>
    <section id="main">
      <img src="@/assets/img/main/main.jpg"
           srcset="@/assets/img/main/main@2x.jpg 2x"
           class="main-bg" alt="">

      <img src="@/assets/img/main/main-mobile.png"
           srcset="@/assets/img/main/main-mobile@2x.png 2x"
           class="main-bg-mobile" alt="">

      <div class="main-timer">
        <div class="main-timer__title">{{ timerMessage }}</div>
        <div class="main-timer__count">
          <div class="main-timer__count__item">
            дней:
            <strong>{{ days }}</strong>
          </div>
          <div class="main-timer__count__item">
            часов:
            <strong>{{ hours }}</strong>
          </div>
          <div class="main-timer__count__item">
            минут:
            <strong>{{ minutes }}</strong>
          </div>
        </div>
      </div>

      <div class="main-certificate">
        <img src="@/assets/img/main/main-gift-bg.png"
             srcset="@/assets/img/main/main-gift-bg@2x.png 2x" class="main-certificate__bg" alt="">

        <div class="main-certificate__title">
          Главный приз
        </div>
        <div class="main-certificate__value">
          250 000
        </div>
        <div class="main-certificate__value-desc">
          рублей
        </div>

        <button v-if="!user" @click="openLoginPopup" class="button button-yellow button-yellow--big">Участвовать</button>
        <template v-else>
          <button class="button button-yellow button-yellow--big" @click="triggerQrScan">Загрузить чек</button>
        </template>

      </div>
    </section>

    <section id="how-register">
      <div class="section-title section-title--sticky">
        Как принять участие
      </div>
      <div class="how-register">
        <div class="how-register__sidebar">
          <div class="how-register-nav">
            <div
              v-for="(section, index) in sections"
              :key="index"
              :class="[
                  'how-register-nav__item',
                  { 'is-active': activeSection === index },
                  { 'is-scrolled': section.scrolled }
                ]"
              :id="`register-nav-section-${index + 1}`"
              @click="scrollToSection(index)">
              <div class="how-register-nav__item__count">{{ index + 1 }}</div>
              <div class="how-register-nav__item__name">{{ section.name }}</div>
            </div>
            <div class="how-register-nav__btn">
              <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" class="button button-blue" target="_blank">Правила акции</a>
            </div>
            <div class="how-register-nav__element">
              <img src="@/assets/img/main/sidebar-element.png"
                   srcset="@/assets/img/main/sidebar-element@2x.png 2x" alt="">
            </div>
          </div>
        </div>
        <div class="how-register__content">
          <div v-for="(section, index) in sections" :key="index" class="register-section" :ref="'section-' + index">
            <template v-if="section.component === 'PurchaseSection'">
              <div class="register-section-links">
                <span>Покупайте
                <a href="https://hochland.ru/products/melted/" class="register-section-links__hochland" target="_blank">Hochland</a></span>
                <span>в сети
                <a href="https://dostavka.5ka.ru/" class="register-section-links__five" target="_blank">Пятерочка</a></span>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-item__gr">
                    <span><strong>400</strong>&nbsp;г</span>
                  </div>
                  <div class="product-item__img">
                    <img src="@/assets/img/products/product-1.png" alt="">
                  </div>
                  <div class="product-item__title">
                    Плавленый сыр Hochland с ветчиной
                  </div>
                </div>
                <div class="product-item">
                  <div class="product-item__gr">
                    <span><strong>200</strong>&nbsp;г</span>
                  </div>
                  <div class="product-item__img">
                    <img src="@/assets/img/products/product-2.png" alt="">
                  </div>
                  <div class="product-item__title">
                    Плавленый сыр Hochland с ветчиной
                  </div>
                </div>
                <div class="product-item">
                  <div class="product-item__gr">
                    <span><strong>400</strong>&nbsp;г</span>
                  </div>
                  <div class="product-item__img">
                    <img src="@/assets/img/products/product-3.png" alt="">
                  </div>
                  <div class="product-item__title">
                    Плавленый сыр Hochland Сливочный
                  </div>
                </div>
                <div class="product-item">
                  <div class="product-item__gr">
                    <span><strong>200</strong>&nbsp;г</span>
                  </div>
                  <div class="product-item__img">
                    <img src="@/assets/img/products/product-4.png" alt="">
                  </div>
                  <div class="product-item__title">
                    Плавленый сыр Hochland Сливочный
                  </div>
                </div>
                <div class="product-item">
                  <div class="product-item__gr">
                    <span><strong>150</strong>&nbsp;г</span>
                  </div>
                  <div class="product-item__img">
                    <img src="@/assets/img/products/product-5.png" alt="">
                  </div>
                  <div class="product-item__title">
                    Плавленый сыр Hochland Чизбургер
                  </div>
                </div>
              </div>
            </template>
            <template v-if="section.component === 'RegisterSection'">
              <div class="check-register">
                <p>Регистрируйте чеки с&nbsp;<strong>28&nbsp;июня</strong> по&nbsp;<strong>9&nbsp;августа</strong> 2024&nbsp;года</p>
                <p>
                  <button v-if="!user" @click="openLoginPopup" class="button button-yellow">Загрузить чек</button>
                  <template v-else>
                    <button class="button button-yellow" @click="triggerQrScan">Загрузить чек</button>
                  </template>
                </p>
              </div>
            </template>
            <template v-if="section.component === 'QuizSection'">
              <div class="quiz-block">
                <div class="quiz-block__title">
                  Пройдите викторину на сайте и выиграйте <strong>Сертификат на экскурсию</strong> в центре Петербурга на двоих!
                </div>

                <div class="quiz-block__img">
                  <img src="@/assets/img/main/victorina-mobile-img.png"
                       srcset="@/assets/img/main/victorina-mobile-img@2x.png 2x" alt="">
                </div>

                <div class="quiz-block-media">
                  <p>Угадайте с какого берега Невы был сделан снимок,
                    и&nbsp;что находится на берегу напротив.</p>

                  <p>Ответив на все вопросы Вы участвуетe
                    в&nbsp;розыгрыше специального приза!</p>

                  <p><button class="button button-yellow" @click="goToGamePage">Играть</button></p>
                </div>
              </div>
            </template>
            <template v-if="section.component === 'PrizeSection'">
              <div class="prizes" id="prizes">
                <div class="prizes-garant">
                  <div class="prizes-garant__title">Гарантированные</div>
                  <div class="products-list">
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>1</strong>&nbsp;чек</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-1.png"
                             srcset="@/assets/img/prizes/prizes-1@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        50 рублей
                      </div>
                    </div>
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>1</strong>&nbsp;чек</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-2.png"
                             srcset="@/assets/img/prizes/prizes-2@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        100 рублей
                      </div>
                    </div>
                  </div>
                </div>
                <div class="prizes-special">
                  <div class="prizes-garant__title">Специальный</div>
                  <div class="products-list">
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>1</strong>&nbsp;чек</span>
                        <span><strong>1</strong>&nbsp;игра</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-3.png"
                             srcset="@/assets/img/prizes/prizes-3@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        Сертификат
                        <br>на экскурсию
                      </div>
                    </div>
                  </div>
                </div>
                <div class="prizes-weekly">
                  <div class="prizes-weekly__title">
                    Еженедельные
                  </div>
                  <div class="products-list">
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>1</strong>&nbsp;чек</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-4.png"
                             srcset="@/assets/img/prizes/prizes-4@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        Портативная
                        <br>колонка JBL
                      </div>
                    </div>
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>3</strong>&nbsp;чека</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-5.png"
                             srcset="@/assets/img/prizes/prizes-5@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        Умная колонка
                        <br>Станция Миди
                      </div>
                    </div>
                    <div class="product-item">
                      <div class="product-item__gr">
                        <span><strong>5</strong>&nbsp;чеков</span>
                      </div>
                      <div class="product-item__img">
                        <img src="@/assets/img/prizes/prizes-6.png"
                             srcset="@/assets/img/prizes/prizes-6@2x.png 2x" alt="">
                      </div>
                      <div class="product-item__title">
                        Наушники
                        <br>AirPods Pro 2
                      </div>
                    </div>
                  </div>
                </div>
                <div class="prizes-main">
                  <img src="@/assets/img/prizes/main-desktop.png"
                       srcset="@/assets/img/prizes/main-desktop@2x.png 2x"
                       class="prizes-main__desktop" alt="">

                  <img src="@/assets/img/prizes/main-mobile.png"
                       srcset="@/assets/img/prizes/main-mobile@2x.png 2x"
                       class="prizes-main__mobile" alt="">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section id="winners">
      <div class="section-title section-title--sticky-light">
        Победители
      </div>
      <div class="winners">
        <div class="winners__sidebar">
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 1, 'button-empty': !availableWeeks[1] }"
            @click="selectWeek(1)">10 июля 2024 г.
          </button>
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 2, 'button-empty': !availableWeeks[2] }"
            @click="selectWeek(2)">17 июля 2024 г.
          </button>
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 3, 'button-empty': !availableWeeks[3] }"
            @click="selectWeek(3)">24 июля 2024 г.
          </button>
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 4, 'button-empty': !availableWeeks[4] }"
            @click="selectWeek(4)">31 июля 2024 г.
          </button>
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 5, 'button-empty': !availableWeeks[5] }"
            @click="selectWeek(5)">7 августа 2024 г.
          </button>
          <button
            class="button button-blue"
            :class="{ 'button-blue-active': selectedWeek === 6, 'button-empty': !availableWeeks[6] }"
            @click="selectWeek(6)">14 августа 2024 г.
          </button>
          <button
            class="button button-white"
            :class="{ 'button-blue-active': selectedWeek === 7, 'button-empty': !availableWeeks[7] }"
            @click="selectWeek(7)">Главный
          </button>
        </div>
        <div class="winners__content">
          <div v-for="(week, index) in weeks" :key="index" class="accordion">
            <button class="mobile-toggle-buttons button button-blue"
                    :ref="el => buttonRefs[week.index] = el"
                    :class="{ 'is-opened': selectedWeek === week.index }"
                    @click="toggleWinnersAccordion(week)">
              {{ week.date }}
            </button>
            <div class="accordion__content" v-show="selectedWeek === week.index">
              <div v-if="winners.length === 0" class="no-winners">
                <div v-if="nextDrawDate == 'Главный'">
                  <div v-if="mainWinner.name">
                    <div class="main-winner main-winner--win">
                      <div class="main-winner__title">
                        Поздравляем победителя!
                      </div>
                      <div class="main-winner__name">
                        {{ mainWinner.name }}
                        <br>{{ mainWinner.phone }}
                      </div>
                      <div class="main-winner__prize">
                        250 000 рублей
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="main-winner">
                      <div class="main-winner__title">
                        Розыгрыш состоится 26 августа.
                      </div>
                      <div class="main-winner__text">
                        <p>Совершите покупку акционного товара
                          <br>в магазине сети Пятерочка в период
                          <br>с 28.06.2024 по 26.08.2024 гг.</p>

                        <p>Для участие в розыгрыше, загрузи не менее 10 чеков.
                          <br>Чем больше чеков будет загружено, тем выше шанс!</p>
                      </div>
                      <div class="main-winner__btn">
                        <button v-if="!user" @click="openLoginPopup" class="button button-white">Загрузить чек на сайте</button>
                        <template v-else>
                          <button class="button button-white" @click="triggerQrScan">Загрузить чек на сайте</button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="winners-empty">
                    <div class="winners-empty__title">
                      Розыгрыш состоится {{ nextDrawDate }}.
                      <br>Успей принять участие в розыгрыше призов!
                    </div>
                    <div class="winners-empty__btn">
                      <button v-if="!user" @click="openLoginPopup" class="button button-yellow">Загрузить чек на сайте</button>
                      <template v-else>
                        <button class="button button-yellow" @click="triggerQrScan">Загрузить чек на сайте</button>
                      </template>
                      <button class="button button-yellow" @click="goToGamePage">Участвовать в викторине</button>
                    </div>
                    <div class="winners-empty__list">
                      <div class="winning-prizes-list">
                        <div class="prize-item" v-for="(prize, index) in filteredPrizes" :key="index">
                        <div class="prize-item__info"></div>
                          <div class="prize-item__img">
                            <img :src="prize.cabinet_image">
                          </div>
                          <div class="prize-item__title">{{ prize.title }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="winning-prizes-list">
                  <div class="prize-item" v-for="(winner, index) in winners" :key="index">
                    <div class="prize-item__info">
                      <div class="prize-item__info-user">
                        {{ winner.winner.name }}
                      </div>
                      <div class="prize-item__info-phone">
                        {{ winner.winner.phone_number }}
                      </div>
                    </div>
                    <div class="prize-item__img">
                      <img :src="winner.prize.cabinet_image" alt="Prize Image">
                    </div>
                    <div class="prize-item__title">{{ winner.prize.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq">
      <div class="section-title section-title--sticky section-title--sticky--clear">
        FAQ
      </div>

      <div class="faq-block">
        <div class="faq-block__left">
          <div class="faq-sticky">
            <p><span>Остались вопросы?</span></p>
            <p>Напишите нам и мы
              обязательно свяжемся с Вами!</p>
            <p><button @click="openQuestionPopup" class="button button-yellow">Задать вопрос</button></p>
          </div>
        </div>
        <div class="faq-block__right">
          <div class="faq-item" v-for="faq in faq" :key="faq">
            <button class="faq-item__btn" @click="toggleAccordion">
              {{ faq.question }}
            </button>
            <div class="faq-item__text" v-html="formatText(faq.answer)"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask>
                <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Войти</button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-white" @click="openRegisterPopup">Регистрация</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">с обработкой персональных данных</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Регистрация</button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-white" @click="switchToLogin">Войти</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за регистрацию.
            <br>Пароль для входа отправлен в&nbsp;смс
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для вопроса -->
    <div v-if="showQuestionPopup" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopup"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopup" class="popup__close"></button>
        <div class="popup__title">Обратная связь</div>
        <div class="popup__content">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                  type="text"
                  v-model="questionForm.name"
                  id="feedback-name"
                  name="name"
                  @input="removeSpacesName"
                  :disabled="user && !!user.first_name" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-email">Email</label>
                <input
                  type="text"
                  v-model="questionForm.email"
                  id="feedback-email"
                  name="email"
                  :disabled="user && !!user.email" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Сообщение</label>
                <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Согласен(на) <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank" class="link">с обработкой персональных данных</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваше обращение принято
            <br>Мы свяжемся с вами в ближайшее время
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                       :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
                <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
          <div class="success-message">
            Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для временного закрытия ЛК -->
    <div v-if="AccountPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountPopup" class="popup__close"></button>
        <div class="popup__title">
          Личный кабинет
        </div>
        <div class="popup__content">
          <div class="success-message">
            Личный кабинет временно недоступен.
            <br>Мы уже знаем о проблеме и работаем над ее устранением
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword, getWinningsByWeek, getPrizes } from '@/api';

export default {
  name: 'HomePage',
  setup() {
    const mainWinner = ref({
      name: '',
      phone: '',
    });

    const selectedWeek = ref(1);
    const winners = ref([]);
    const nextDrawDate = ref('10 июля');
    const availableWeeks = ref({});
    const prizes = ref([]);
    const weeks = ref([
      { index: 1, date: '10 июля 2024 г.' },
      { index: 2, date: '17 июля 2024 г.' },
      { index: 3, date: '24 июля 2024 г.' },
      { index: 4, date: '31 июля 2024 г.' },
      { index: 5, date: '7 августа 2024 г.' },
      { index: 6, date: '14 августа 2024 г.' },
      { index: 7, date: 'Главный' },
    ]);

    const buttonRefs = ref({});

    const fetchWinnings = async (week) => {
      try {
        const data = await getWinningsByWeek(week);
        if (data.count > 0) {
          winners.value = data.results;
        } else {
          winners.value = [];
        }
      } catch (error) {
        console.error('Error fetching winnings:', error);
        winners.value = [];
      }
    };

    const fetchPrizes = async () => {
      try {
        const data = await getPrizes();
        prizes.value = data;
      } catch (error) {
        console.error('Error fetching prizes:', error);
        prizes.value = [];
      }
    };

    const checkWeeksAvailability = async () => {
      const weeksToCheck = [1, 2, 3, 4, 5, 6, 7];

      for (const week of weeksToCheck) {
        try {
          const data = await getWinningsByWeek(week);
          availableWeeks.value[week] = data.count > 0;
          const specialPrize = data.results.find(item => item.prize.type === 2);
          if (specialPrize) {
            mainWinner.value.name = specialPrize.winner.name;
            mainWinner.value.phone = specialPrize.winner.phone_number;
          }
        } catch (error) {
          availableWeeks.value[week] = false;
        }
      }

      selectWeek(findLastActiveWeek());
    };

    const selectWeek = (week) => {
      selectedWeek.value = week;
      fetchWinnings(week);
      updateNextDrawDate(week);
    };

    const findLastActiveWeek = () => {
      for (let week = 7; week >= 1; week--) {
        if (availableWeeks.value[week]) {
          return week;
        }
      }
      return 1; // Default to week 1 if no active weeks found
    };

    const updateNextDrawDate = (week) => {
      const dates = [
        '10 июля',
        '17 июля',
        '24 июля',
        '31 июля',
        '7 августа',
        '14 августа',
        'Главный',
      ];
      nextDrawDate.value = dates[week - 1];
    };

    const toggleWinnersAccordion = (week) => {
      if (selectedWeek.value === week.index) {
        selectedWeek.value = null;
      } else {
        selectWeek(week.index);

        // Прокрутка к началу кнопки
        if (window.innerWidth <= 767) { // Условие для мобильных устройств
          nextTick(() => {
            buttonRefs.value[week.index].scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
        }
      }
    };

    onMounted(async () => {
      await fetchPrizes();
      await checkWeeksAvailability();
    });

    return {
      selectedWeek,
      winners,
      nextDrawDate,
      availableWeeks,
      prizes,
      mainWinner,
      selectWeek,
      weeks,
      toggleWinnersAccordion,
      buttonRefs,
    };
  },
  data() {
    return {
      rootUrl: window.location.origin,
      userId: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  mounted() {
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    filteredPrizes() {
      return this.prizes.filter(prize => prize.type === 1);
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    goToGamePage() {
      this.$router.push('/game');
      setTimeout(function(){
        window.scrollTo({
          top: '0',
          behavior: 'smooth'
        });
      }, 200)
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.registerForm.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.registerForm.last_name = input;
    },
    removeSpacesName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.questionForm.name = input;
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.showQuestionPopup = true;
    },
    closeQuestionPopup() {
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = true;
    },
    closeQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
    },
    closeResetPasswordPopup() {
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    async submitQuestion() {
      try {
        // Валидация формы перед отправкой
        this.feedbackErrors = {};
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return; // Если есть ошибки валидации, не отправляем запрос
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text
        });

        // Закрыть попап после успешной отправки
        this.openQuestionPopupSuccess();
        this.closeQuestionPopup();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если акция уже завершилась
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    closeLoginPopup() {
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
    },
    closeRegisterPopup() {
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.$router.push('/private');
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        if (!email.includes('@') || !email.includes('.')) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email);

        // Закрываем окно регистрации
        this.closeRegisterPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
    },
    logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = '';
      }
      this.toggleMobileNav();
      removeToken();
      this.questionForm = {
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };
      this.user = null;
      this.$router.push('/');
    },
  },
};
</script>

<style>
/* Ваши стили */
</style>
