<template>
  <div class="quiz-results">
    <div class="quiz">
      <div class="quiz__left">
        <p>Правильных ответов: {{ correctAnswersCount }} из 7</p>
        <div class="quiz-results__title">
          {{ congratulationMessage }}
        </div>
        <p>
          Поделитесь своими результатами в социальных сетях:
          <span class="social-links">
            <a :href="'https://t.me/share/url?url=' + encodeURIComponent(rootUrl)" target="_blank">
              <img src="@/assets/img/icons/tg.svg" alt="">
            </a>
            <a :href="'https://vk.com/share.php?url=' + encodeURIComponent(rootUrl)" target="_blank">
              <img src="@/assets/img/icons/vk.svg" alt="">
            </a>
            <a :href="'https://connect.ok.ru/offer?url=' + encodeURIComponent(rootUrl)" target="_blank">
              <img src="@/assets/img/icons/ok.svg" alt="">
            </a>
          </span>
        </p>
        <div v-if="!isAuthenticated" class="auth-prompt">
          <p>
            Для сохранения результата войдите в свой профиль
            <button class="button button-yellow" @click="openLoginPopup">Войти</button>
          </p>
        </div>
      </div>
      <div class="quiz__right" v-if="resultImage" :data-img="resultImage">
      </div>
    </div>


    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask>
                <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Войти</button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-white" @click="openRegisterPopup">Регистрация</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">с обработкой персональных данных</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Регистрация</button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-white" @click="switchToLogin">Войти</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за регистрацию.
            <br>Пароль для входа отправлен в&nbsp;смс
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                       :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
                <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
          <div class="success-message">
            Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  authorize,
  fetchFAQ,
  getMainSettings,
  getToken,
  getUserInfo, register, removeToken,
  sendFeedback,
  sendResetPassword,
  setAuthHeader
} from "@/api";

export default {
  data() {
    return {
      rootUrl: window.location.origin,
      userId: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  mounted() {
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  name: 'QuizResults',
  props: {
    correctAnswersCount: {
      type: Number,
      required: true
    },
    resultImage: {
      type: String,
      default: ''
    },
    congratulationMessage: {
      type: String,
      default: ''
    },
    quizToken: {
      type: String,
      required: true
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.registerForm.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.registerForm.last_name = input;
    },
    removeSpacesName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.questionForm.name = input;
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.showQuestionPopup = true;
    },
    closeQuestionPopup() {
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = true;
    },
    closeQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
    },
    closeResetPasswordPopup() {
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    async submitQuestion() {
      try {
        // Валидация формы перед отправкой
        this.feedbackErrors = {};
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return; // Если есть ошибки валидации, не отправляем запрос
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text
        });

        // Закрыть попап после успешной отправки
        this.openQuestionPopupSuccess();
        this.closeQuestionPopup();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если акция уже завершилась
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    closeLoginPopup() {
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
    },
    closeRegisterPopup() {
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password, this.quizToken );
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.$router.push('/private').then(() => {
                // Обновляем страницу после редиректа на /private
                window.location.reload();
              }).catch(err => {
                console.error('Error navigating to /private:', err);
              });
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        if (!email.includes('@') || !email.includes('.')) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email);

        // Закрываем окно регистрации
        this.closeRegisterPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
    },
    logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = '';
      }
      this.toggleMobileNav();
      removeToken();
      this.questionForm = {
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };
      this.user = null;
      this.$router.push('/');
    },
  },
  computed: {
    isAuthenticated() {
      return !!getToken(); // Замените на вашу реализацию
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  }
};
</script>

<style scoped>
</style>
